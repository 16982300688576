.language-selector {
    width: 120px;
    height: 30px;
    margin-top: 2px;

    .selector {
        width: 100%;
        height: 100%;
        
        .ant-select-selector {
            border-radius: 2px;
        }
    }
}

.flag {
    margin-top: -4px;
}