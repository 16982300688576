.seo-content-container {
    background-color: white;
    width: 240px;
    height: 150px;
    border-radius: 5px;
    cursor: pointer;

    .seo-content-title {
        font-size: 20px;
        text-transform: capitalize;
        padding: 10px 0;
        max-height: 80px;
        padding: 10px;
    }

    .seo-content-text {
        overflow: hidden;
        font-size: 0.8em;
        opacity: 60%;
        line-height: 1.5em;
        max-height: 100px;
        padding: 10px;
    }
}