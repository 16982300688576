.create-seo-content-container {
    background-color: rgb(243, 243, 243);
    padding: 40px 150px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .save-button {
        display: flex;
        justify-content: end;
        margin-bottom: 20px;

        button {
            border-radius: 2px;
            height: 40px;
            font-size: 18px;
            background-color: white;
            color: rgb(0, 136, 255);

            &:hover {
                background-color: rgb(80, 131, 185);
                color: white;
            }
        }
    }

    .editor-container {
        width: 100%;
        height: 100%;
    }
}