.edit-save-button {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;

    button {
        border-radius: 2px;
        height: 40px;
        font-size: 18px;
        background-color: white;
        color: rgb(0, 136, 255);

        &:hover {
            background-color: rgb(80, 131, 185);
            color: white;
        }
    }

    .right {
        display: flex;
        gap: 10px;

        .delete-button {
            color: red;
    
            &:hover {
                color: white;
                background-color: red;
            }
        }
    }

}

.custom-disabled-button{
    button {
        color: #a0a0a0 !important; 
        cursor: not-allowed;
    }
}
  
