.login-page{
    width: 100vw;
    height: 100vh;
    background-image: url("https://loncapazar.s3.eu-north-1.amazonaws.com/blogger/office-table-with-cup-coffee-keyboard-notepad.jpg");
    background-size: 120vw 100vh;
    background-repeat: no-repeat;
    background-position: center; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
        position: absolute;
        top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Times New Roman", Times, serif;
        font-size: 60px;
    }

    .login-container {
        width: 30%;
        height: 40%;
        margin: auto;

        .login-welcome {
            margin-bottom: 50px;
            font-size: 1.5em;
        }
    }
  }