.blogs-container {
    background-color: rgb(243, 243, 243);
    height: 100%;
    padding: 40px 100px;

    .blogs-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            padding: 0 0 45px 30px;
            text-align: left;
            font-size: 24px;
        }

        .filters {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;

            .filter {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                font-size: 0.9rem;
            }
        }
    }

    .blogs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        row-gap: 50px;
    }
}