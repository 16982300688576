.blog-container {
    background-color: white;
    width: 240px;
    height: 480px;
    border-radius: 5px;
    cursor: pointer;

    .blog-image {
        img {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
    }

    .blog-title {
        font-size: 20px;
        text-transform: capitalize;
        padding: 10px 0;
        max-height: 80px;
        padding: 10px;
    }

    .blog-text {
        overflow: hidden;
        font-size: 0.8em;
        opacity: 60%;
        line-height: 1.5em;
        max-height: 100px;
        padding: 10px;
    }
}