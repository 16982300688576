.layout {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-areas: 'aside section';

    .layout-aside {
        grid-area: aside;
        border-right: 0.5px solid rgb(193, 193, 193);
        border-left: 0.5px solid rgb(193, 193, 193);
        border-bottom: 0.5px solid rgb(193, 193, 193);
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: fixed;
        width: 300px;
        height: 100vh;

        .logo {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-family: "Times New Roman", Times, serif;
            border-bottom: 0.5px solid rgb(193, 193, 193);
        }

        .link-container {
            padding: 50px 0px;

            .link {
                height: 70px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                padding-left: 40px;
                text-decoration: none;
                color: black;
                font-size: 16px;

                &:hover {
                    color: rgb(230, 184, 34);
                    border-bottom: 0.5px solid rgb(193, 193, 193);
                    border-top: 0.5px solid rgb(193, 193, 193);
                }
            }

            .selected {
                border-right: 2px solid rgb(230, 184, 34);
            }
        }
    }

    .main-layout {
        grid-area: section;
        width: 100%;
        height: 100%;

        .layout-section {
            width: 100%;
            height: calc(100% - 140px);
        }

        .layout-nav {
            height: 60px;
            padding: 0 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.5rem;
            border-bottom: 0.5px solid rgb(193, 193, 193);
            background-color: rgb(80, 131, 185);

            .header {
                color: white;
                margin-left: 30px;
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 10px;
                
                .logout, .selector {
                    margin-right: 20px;
                    margin-top: -5px;
                    font-size: 20px;
                    
                    button {
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}
  
  
  