@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.writer-and-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    .blog-writer {
        display: flex !important;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .writer-label {
            width: 25%;
            align-items: center;
            margin-bottom: 40px;
        }

        .writer-field {
            width: 63%;
            height: 100%;
            margin-bottom: 40px;
            gap: 10px;
            align-items: center;
            background-color: white;
            border-radius: 0;
            height: 40px;
            line-height: 30px;
    
            .ant-select-selector {
                height: 100%;
                line-height: 35px;
                border-radius: 0px;
    
                span {
                    line-height: 35px;
                }
            }
        }
    }

    .blog-main-image {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    .blog-main-image {
        .upload {
            max-width: 100px;
        }

        .image-label {
            text-align: left !important;
            padding: 10px;
            max-height: 20px;
            width: 35%;
        }
    }
}

.blog-field {
    display: flex !important;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .field {
        margin-bottom: 40px;
        gap: 10px;
        align-items: center;
        background-color: white;
        border-radius: 0;
        height: 40px;
        line-height: 30px;
        width: 85%;
    }

    .react-datepicker-wrapper{
        width: 100%;
    }

    .custom-date-picker{
        border-color: white;
    }

    .language-field {
        width: 85%;

        .ant-select-selection-item {
            line-height: 35px;
        }
        
        div {
            border-radius: 0;
            height: 40px !important;
        }
    }

    .category-field {
        width: 45%;

        .ant-select-selection-item {
            line-height: 35px;
        }
        
        div {
            border-radius: 0;
            height: 40px !important;
        }
    }
}

.category-field-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .ant-select-arrow {
        display: none;
    }
}

.field-margin {
    margin-top: 2rem;
}

.label {
    text-align: left !important;
    padding: 10px;
    max-height: 20px;
    width: 15%;
}

.added-categories {
    max-height: 150px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
    margin-left: 2rem;

    h5 {
        margin: 0.4rem 0;
    }

    .category-line {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.3rem 0;
        border-bottom: 0.5px solid rgb(212, 212, 212);

        .category-line-remove {
            margin-left: 1rem;
            padding: 0.2rem 0.3rem;
            border: 1px solid black;
            border-radius: 0.3rem;
            cursor: pointer;

            &:hover {
                background-color: rgb(218, 218, 218);
            }
        }
    }
}

.up {
    margin-top: -8px;
}

.quill {

    margin-top: 20px;

    .ql-editor {
        min-height: 400px !important;
        height: 650px;
        font-size: 19.2px;
        background-color: white !important;
        overflow: scroll;
        font-family: Nantes, "Times New Roman", Times, serif;
        color: rgb(33, 37, 41);
        font-style: normal;

        a {
            color: #06c !important;
        }

        p {
            line-height: 28.8px;
            margin-bottom: 16px;
            margin-top: 0;
            font-size: 19.2px;
            font-weight: 400;
        }

        h1 {
            font-size: 42.24px;
            font-weight: 700;
            line-height: 50.68px;
            margin: 20px 0px
        }

        h2 {
            font-weight: 700;
            line-height: 57.6px;
            font-size: 28.8px;
            margin-bottom: 8px;
        }

        h3 {
            font-weight: 700;
            line-height: 46.08px;
            font-size: 23.04px;
            margin: 16px 0;
        }

        h4 {
            font-weight: 700;
            line-height: 72px;
            font-size: 24px;
            margin-bottom: 8px;
        }

        ol, ul, dl {
            margin-top: 0;
            margin-bottom: 16px;
        }

        ol, ul {
            padding-left: 32px;
        }

        img {
            margin-top: 16px;
        }
    }

}

.reminders {
    background-color: rgb(223, 233, 241);
    width: 280px;
    max-height: 500px;
    min-height: 450px;
    overflow: scroll;
    position: fixed;
    left: 0;
    bottom: 0;
    box-shadow: inset -5px 10px 10px  #f2f6f8;
    padding: 10px;

    div {
        margin: 30px 0;

        .reminders-label {
            padding: 15px;
        }

        .reminder-text {
            background-color: white;
            color: rgb(86, 86, 86);
        }
    }

    .reminders-title {
        padding: 10px;
        font-size: 20px;
        text-decoration: underline;
    }

    @media only screen and (max-height: 750px) {
        max-height: 300px;
        min-height: 200px;
    }

    @media only screen and (max-height: 600px) {
        max-height: 200px;
        min-height: 100px;
    }
}

.alt-editor {
    width: 400px;
    height: 50px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 10px;
    display: none;
    position: fixed;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        line-height: 45px;
        padding: 0 10px;

        input {
            height: 20px;
            font-size: 14px;
        }

        button {
            height: 25px;
            border: 1px solid black;
            background-color: rgb(152, 243, 243);
            font-size: 14px;
        }
    }
}